
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetsRT () {
    function repeatFacets1(themeAssetsUrl, facets, facetsIndex) {
        return [facets(function () {
                function onClick1() {
                    console.log(this);
                }
                function repeatShowAlwaysValues2(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                    return [ShowAlwaysValues(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                        }, { count: undefined })];
                }
                function repeatValues3(Values, ValuesIndex) {
                    return [Values(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                        }, { count: undefined })];
                }
                function repeatValues4(Values, ValuesIndex) {
                    return [Values(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, !this.isExtraRange ? _createElement('span', { 'key': '217' }, this.value) : null, this.isExtraRange && this.finite ? _createElement('span', { 'key': '278' }, '$', this.range[0], ' - $', this.range[1]) : null, this.isExtraRange && this.toPosInf ? _createElement('span', { 'key': '394' }, '$', this.range[0], ' and more') : null, this.isExtraRange && this.toNegInf ? _createElement('span', { 'key': '497' }, '$', this.range[1], ' and less') : null), !this.isExtraRange ? _createElement('div', {
                                'className': 'facetentryval',
                                'key': '609'
                            }, this.hitCount) : null);
                        }, { count: undefined })];
                }
                function scopeDollarInchMm5() {
                    var dollar = [
                        'price',
                        'Price'
                    ].includes(this.field) ? '$' : '';
                    var inch = [].includes(this.field) ? '"' : '';
                    var mm = [
                        'wheel_bore',
                        'wheel_offset'
                    ].includes(this.field) ? 'mm' : '';
                    return _createElement('div', {
                        'className': 'cmTemplate_sliderFacet',
                        'key': '12531'
                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, dollar, this.min, inch, mm), _createElement('div', { 'className': 'max' }, dollar, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                        return _createElement('div', {
                                            'placeholder': 'Min',
                                            'className': 'cm_inputMin'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                'className': 'separator',
                                'key': '334'
                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                        return _createElement('div', {
                                            'placeholder': 'Max',
                                            'className': 'cm_inputMax'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, _createElement('button', {
                                'type': 'button',
                                'className': 'cm_btn cm_button cm_button__secondary',
                                'data-cm-role': 'add-facet'
                            }, '\n    GO\n  '));
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }
                function repeatShowAlwaysValues6(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                    return [ShowAlwaysValues(function () {
                            function scopeStarFill1(i, iIndex) {
                                var starFill = this.value - i;
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                });
                            }
                            function repeatI2(i, iIndex, starFill) {
                                return scopeStarFill1.apply(this, [
                                    i,
                                    iIndex
                                ]);
                            }
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, _createElement.apply(this, [
                                'span',
                                { 'className': 'cm_review-stars' },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI2.bind(this))
                            ]), this.value !== '5' ? _createElement('span', {
                                'className': 'cm_and-up',
                                'key': '499'
                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                        }, { count: undefined })];
                }
                function repeatValues7(Values, ValuesIndex) {
                    return [Values(function () {
                            function scopeStarFill1(i, iIndex) {
                                var starFill = this.value - i;
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                });
                            }
                            function repeatI2(i, iIndex, starFill) {
                                return scopeStarFill1.apply(this, [
                                    i,
                                    iIndex
                                ]);
                            }
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, _createElement.apply(this, [
                                'span',
                                { 'className': 'cm_review-stars' },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI2.bind(this))
                            ]), this.value !== '5' ? _createElement('span', {
                                'className': 'cm_and-up',
                                'key': '499'
                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                        }, { count: undefined })];
                }
                function repeatShowAlwaysValues8(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                    return [ShowAlwaysValues(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                'className': 'facetimg' + (this.field === 'product_type' ? ' facetimg-product_type' : ''),
                                'src': this.field === 'vendor' ? this.imageUrl : themeAssetsUrl + `part-${ this.term.toLowerCase() }.png`,
                                'onError': event => event.target.removeAttribute('src')
                            }), _createElement('div', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, this.value));
                        }, { count: undefined })];
                }
                function repeatValues9(Values, ValuesIndex) {
                    return [Values(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                'className': 'facetimg' + (this.field === 'product_type' ? ' facetimg-product_type' : ''),
                                'src': this.field === 'vendor' ? this.imageUrl : themeAssetsUrl + `part-${ this.term.toLowerCase() }.png`,
                                'onError': event => event.target.removeAttribute('src')
                            }), _createElement('div', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, this.value));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                    'className': 'facettitle',
                    'data-cm-role': 'toggle-facet',
                    'tabIndex': '0'
                }, _createElement('span', { 'onClick': onClick1.bind(this) }, this.name), this.isCollapsed ? [_createElement('svg', {
                        'className': 'cm_icon cm_icon-angle left',
                        'height': '20px',
                        'role': 'img',
                        'viewBox': '39 30 565 565',
                        'key': '2000'
                    }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                        'className': 'cm_icon cm_icon-angle down',
                        'height': '20px',
                        'role': 'img',
                        'viewBox': '39 30 565 565',
                        'key': '5020'
                    }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                    'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                    'role': 'list'
                }, this.template === 'simpleFacet' ? _createElement('div', {
                    'className': 'cmTemplate_simpleFacet',
                    'key': '1003'
                }, this.showFilterInput ? _createElement('div', {
                    'className': 'filter-input',
                    'key': '1089'
                }, [this.filterInput(function () {
                        return _createElement('div', {
                            'className': 'input  cm_filterInput',
                            'placeholder': 'Enter'
                        });
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], this.inputNotEmpty ? _createElement('span', {
                    'className': 'filter-input_clear-container',
                    'onClick': this.clearInput,
                    'key': '1409'
                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                        'className': 'facetdiv',
                        'key': '15781'
                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues2.bind(this))
                ]), _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_Values' },
                    _map(this.Values, repeatValues3.bind(this))
                ]), this.needShowMore ? _createElement('div', {
                    'className': 'facetdiv cm_show-all-container',
                    'key': '3409'
                }, _createElement('a', {
                    'className': 'cm_show-all',
                    'data-cm-role': 'toggle-show-more',
                    'tabIndex': '0'
                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                    'className': 'cmTemplate_selectFacet',
                    'key': '3735'
                }, [this.select(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3543'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3745'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'key': '62'
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled
                        }, this.useNativeDropdown ? [
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n          ', '', this.title, '\n        ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '7771'
                                }, 'Popular Makes'),
                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '7774'
                                }, 'All Makes'),
                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                            ] : null,
                            this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                            'key': '_current',
                            'value': '_current'
                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'key': '2865'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      ', '', this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                        ]) : null));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                    'className': 'cmTemplate_priceFacet',
                    'key': '9638'
                }, !this.ranges.length ? [
                    _createElement('div', { 'key': '97221' }, '$', this.selection[0], ' to $', this.selection[1]),
                    '\n  ',
                    this.slider,
                    '\n  ',
                    _createElement('div', {
                        'className': 'cm_flex',
                        'key': '97223'
                    }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                ] : null, this.ranges.length ? [_createElement.apply(this, [
                        'div',
                        {
                            'className': 'cmRepeater_Values',
                            'key': '99901'
                        },
                        _map(this.Values, repeatValues4.bind(this))
                    ])] : null, [this.Inputs(function () {
                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                return _createElement('div', {
                                    'placeholder': 'Min price',
                                    'className': 'cm_inputMin'
                                });
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                return _createElement('div', {
                                    'placeholder': 'Max price',
                                    'className': 'cm_inputMax'
                                });
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })], _createElement('button', {
                            'type': 'button',
                            'className': 'cm_btn cm_button cm_button__secondary',
                            'data-cm-role': 'add-facet'
                        }, '\n    GO\n  '));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm5.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                    'className': 'cmTemplate_reviewFacet',
                    'key': '14444'
                }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues6.bind(this))
                ]), _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_Values' },
                    _map(this.Values, repeatValues7.bind(this))
                ])) : null, this.template === 'tilesFacet' ? _createElement('div', {
                    'className': 'cmTemplate_tilesFacet',
                    'key': '17563'
                }, this.showFilterInput ? _createElement('div', {
                    'className': 'filter-input',
                    'key': '17647'
                }, [this.filterInput(function () {
                        return _createElement('div', {
                            'className': 'input  cm_filterInput',
                            'placeholder': 'Enter'
                        });
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], this.inputNotEmpty ? _createElement('span', {
                    'className': 'filter-input_clear-container',
                    'onClick': this.clearInput,
                    'key': '17967'
                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                        'className': 'facetdiv',
                        'key': '181361'
                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues8.bind(this))
                ]), _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_Values' },
                    _map(this.Values, repeatValues9.bind(this))
                ]), this.needShowMore ? _createElement('div', {
                    'className': 'facetdiv cm_show-all-container',
                    'key': '20143'
                }, _createElement('a', {
                    'className': 'cm_show-all',
                    'data-cm-role': 'toggle-show-more'
                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null));
            }, { count: undefined })];
    }
    function scopeThemeAssetsUrl2() {
        var themeAssetsUrl = window.Convermax.config.themeAssetsUrl;
        return _createElement.apply(this, [
            'div',
            { 'className': 'cmRepeater_facets' },
            _map(this.facets, repeatFacets1.bind(this, themeAssetsUrl))
        ]);
    }
    return scopeThemeAssetsUrl2.apply(this, []);
}
        export const componentNames = ["cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:filterInput"]