import shopifyGenericDefaults from '../shopify-generic/config.js';

import {
  getVehicleString,
  updateVehicleVisualization,
  setStoreConfig,
} from '../_auto-sync/autosyncVisualizer.js';

const facetWidget = shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets');

let searchResultsVehicleVisualizer = null;

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  const vehicleString = getVehicleString();
  if (!vehicleString) {
    return;
  }

  searchResultsVehicleVisualizer = updateVehicleVisualization({
    autoSyncVisualizer: searchResultsVehicleVisualizer,
    autoSyncConfig: {
      elId: 'cm_vehicle-visualization',
      height: '150px',
      vehicleImageSize: 640,
      vehicleAngles: [32],
      showColorPicker: false,
    },
    vehicleString,
  });
}

const onVehicleDiscarded = () => {
  [...window.document.body.classList]
    .filter((cl) => cl.startsWith('cm_') && (cl.endsWith('-visualized') || cl.endsWith('-failed')))
    .forEach((className) => {
      window.document.body.classList.remove(className);
    });
};

const InitFunc = () => {
  shopifyGenericDefaults.InitFunc?.();
  setStoreConfig('10secondracing-com', ['Year', 'Make', 'Model', 'Submodel']);
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  facets: {
    ...shopifyGenericDefaults.facets,
    simpleFacet: [
      { fields: ['vendor', 'product_type'], name: 'tilesFacet' },
      { fields: ['review'], name: 'reviewFacet' },
    ],
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    isVehicleSelectionIsolated: true,
    onVehicleDiscarded,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['Facets', 'SearchResult'].includes(w.name)),
    {
      ...facetWidget,
      template: 'Facets',
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'VehicleWheelDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/vehicleWheelDialog',
    },
    {
      name: 'VehicleWheelDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'VehicleWheelDialog',
    },
  ],
};
